<template>
  <div class="box_bidu">
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback" />
        <span>代扣列表</span>
      </div>
    </div>
    <van-tabs
      class="tabsty"
      v-model="active"
      color="#666666"
      @change="changedata"
      title-active-color="#3399CC"
    >
      <van-tab title="全部订单">
        <div class="busPaStyle orderAll">
          <div v-if="!showkong">
            <van-collapse v-model="activeNames" accordion>
              <van-collapse-item
                v-for="(item, index) in orderAll"
                :key="index"
                :name="item.id"
              >
                <template #title>
                  <div @click="changeItem(item)" class="in_box">
                    <div>姓名：{{ item.withholdingName }}</div>
                    <div>
                      <div v-if="item.deductionType == 1">
                        订单类型：单笔扣款
                      </div>
                      <div v-if="item.deductionType == 2">
                        订单类型：分期扣款
                      </div>
                    </div>
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 1"
                      type="info"
                      >订单完成</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 2"
                      plain
                      type="warning"
                      @click.stop="outOrder(item.id)"
                      >取消订单</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 0"
                      type="danger"
                      >订单已取消</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 4"
                      type="danger"
                      >订单未完成</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 3"
                      type="warning"
                      >订单逾期</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 5"
                      type="info"
                      @click.stop="gohet(item)"
                      >继续操作</van-button
                    >
                    <div>订单号：{{ item.orderNo }}</div>
                    <div>订单金额：{{ item.amount }}</div>
                    <div>创建时间：{{ item.createTime }}</div>
                    <div>备注：{{ item.remake }}</div>
                  </div>
                </template>
                <div
                  v-for="(iData, index) in leiData"
                  :key="index"
                  class="insas_box"
                >
                  <div>期数：{{ iData.orderNumber }}</div>
                  <div>扣款时间：{{ iData.planTime }}</div>
                  <div v-if="iData.status == 1" class="stas">
                    扣款状态：<span>扣款成功</span>
                  </div>
                  <div v-if="iData.status == 0" class="stas">
                    扣款状态： <span>扣款失败</span>
                  </div>
                  <div v-if="iData.status == 2" class="stas">
                    扣款状态： <span>未开始</span>
                  </div>
                  <div v-if="iData.status == 3" class="stas">
                    扣款状态：<span>扣款中</span>
                  </div>
                  <div v-if="iData.status == 4" class="stas">
                    扣款状态：<span>取消</span>
                  </div>

                  <div v-if="iData.paymentStatus == 0">
                    提现状态：<span> 未提现</span>
                  </div>
                  <div v-if="iData.paymentStatus == 1">
                    提现状态：<span> 已提现</span>
                  </div>

                  <van-button
                    class="lookdetails"
                    @click="
                      lookDetails(
                        iData,
                        item.withholdingName,
                        item.deductionType
                      )
                    "
                    size="mini"
                    type="info"
                    >查看详情</van-button
                  >
                  <div class="line"></div>
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>

          <div class="inxx_nimg" v-if="showkong">
            <img src="@/assets/images/kong.png" alt="" />
          </div>
          <div class="logindata" v-if="showText">{{ textLogin }}</div>
        </div>
      </van-tab>
      <van-tab title="已完成">
        <div class="busPaStyle">
          <!--  -->
          <div v-if="!showkong">
            <van-collapse v-model="activeNames" accordion>
              <van-collapse-item
                v-for="(item, index) in orderAll"
                :key="index"
                :name="item.id"
              >
                <template #title>
                  <div @click="changeItem(item)" class="in_box">
                    <div>姓名：{{ item.withholdingName }}</div>
                    <div>
                      <div v-if="item.deductionType == 1">
                        订单类型：单笔扣款
                      </div>
                      <div v-if="item.deductionType == 2">
                        订单类型：分期扣款
                      </div>
                    </div>
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 1"
                      type="info"
                      >订单完成</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 2"
                      plain
                      type="warning"
                      @click.stop="outOrder(item.id)"
                      >取消订单</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 0"
                      type="danger"
                      >订单已取消</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 4"
                      type="danger"
                      >订单未完成</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 3"
                      type="warning"
                      >订单逾期</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 5"
                      type="info"
                      @click.stop="gohet(item)"
                      >继续操作</van-button
                    >
                    <div>订单号：{{ item.orderNo }}</div>
                    <div>订单金额：{{ item.amount }}</div>
                    <div>创建时间：{{ item.createTime }}</div>
                    <div>备注：{{ item.remake }}</div>
                  </div>
                </template>
                <div
                  v-for="(iData, index) in leiData"
                  :key="index"
                  class="insas_box"
                >
                  <div>期数：{{ iData.orderNumber }}</div>
                  <div>扣款时间：{{ iData.planTime }}</div>
                  <div v-if="iData.status == 1" class="stas">
                    扣款状态：<span>扣款成功</span>
                  </div>
                  <div v-if="iData.status == 0" class="stas">
                    扣款状态： <span>扣款失败</span>
                  </div>
                  <div v-if="iData.status == 2" class="stas">
                    扣款状态： <span>未开始</span>
                  </div>
                  <div v-if="iData.status == 3" class="stas">
                    扣款状态：<span>扣款中</span>
                  </div>
                  <div v-if="iData.status == 4" class="stas">
                    扣款状态：<span>取消</span>
                  </div>
                  <div v-if="iData.paymentStatus == 0">
                    提现状态：<span> 未提现</span>
                  </div>
                  <div v-if="iData.paymentStatus == 1">
                    提现状态：<span> 已提现</span>
                  </div>

                  <van-button
                    class="lookdetails"
                    @click="
                      lookDetails(
                        iData,
                        item.withholdingName,
                        item.deductionType
                      )
                    "
                    size="mini"
                    type="info"
                    >查看详情</van-button
                  >
                  <div class="line"></div>
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
          <!--  -->
          <!-- 空状态 -->
          <div class="inxx_nimg" v-if="showkong">
            <img src="@/assets/images/kong.png" alt="" />
          </div>
        </div>
      </van-tab>
      <van-tab title="未完成">
        <div class="busPaStyle">
          <!--  -->
          <div v-if="!showkong">
            <van-collapse v-model="activeNames" accordion>
              <van-collapse-item
                v-for="(item, index) in orderAll"
                :key="index"
                :name="item.id"
              >
                <template #title>
                  <div @click="changeItem(item)" class="in_box">
                    <div>姓名：{{ item.withholdingName }}</div>
                    <div>
                      <div v-if="item.deductionType == 1">
                        订单类型：单笔扣款
                      </div>
                      <div v-if="item.deductionType == 2">
                        订单类型：分期扣款
                      </div>
                    </div>
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 1"
                      type="info"
                      >订单完成</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 2"
                      plain
                      type="warning"
                      @click.stop="outOrder(item.id)"
                      >取消订单</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 0"
                      type="danger"
                      >订单已取消</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 4"
                      type="danger"
                      >订单未完成</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 3"
                      type="warning"
                      >订单逾期</van-button
                    >
                    <van-button
                      class="outOrder"
                      size="small"
                      v-if="item.orderStatus == 5"
                      type="info"
                      @click.stop="gohet(item)"
                      >继续操作</van-button
                    >
                    <div>订单号：{{ item.orderNo }}</div>
                    <div>订单金额：{{ item.amount }}</div>
                    <div>创建时间：{{ item.createTime }}</div>
                    <div>备注：{{ item.remake }}</div>
                  </div>
                </template>
                <div
                  v-for="(iData, index) in leiData"
                  :key="index"
                  class="insas_box"
                >
                  <div>期数：{{ iData.orderNumber }}</div>
                  <div>扣款时间：{{ iData.planTime }}</div>
                  <div v-if="iData.status == 1" class="stas">
                    扣款状态：<span>扣款成功</span>
                  </div>
                  <div v-if="iData.status == 0" class="stas">
                    扣款状态： <span>扣款失败</span>
                  </div>
                  <div v-if="iData.status == 2" class="stas">
                    扣款状态： <span>未开始</span>
                  </div>
                  <div v-if="iData.status == 3" class="stas">
                    扣款状态：<span>扣款中</span>
                  </div>
                  <div v-if="iData.status == 4" class="stas">
                    扣款状态：<span>取消</span>
                  </div>
                  <div v-if="iData.paymentStatus == 0">
                    提现状态：<span> 未提现</span>
                  </div>
                  <div v-if="iData.paymentStatus == 1">
                    提现状态：<span> 已提现</span>
                  </div>

                  <van-button
                    class="lookdetails"
                    @click="
                      lookDetails(
                        iData,
                        item.withholdingName,
                        item.deductionType
                      )
                    "
                    size="mini"
                    type="info"
                    >查看详情</van-button
                  >
                  <div class="line"></div>
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
          <!--  -->
          <!-- 空状态 -->
          <div class="inxx_nimg" v-if="showkong">
            <img src="@/assets/images/kong.png" alt="" />
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import {
  getOrderList,
  getOrderPlanListt,
  cancelOrder,
  getOrderStatus,
} from "@/request/api";
export default {
  data() {
    return {
      active: 0,
      orderAll: [],
      leiData: [],
      pageNum: 1,
      pageSize: "8",
      status: "-1",
      showText: false,
      textLogin: "我是有底线的~~~~",
      loginShow: true,
      showkong: false,

      activeNames: [],
    };
  },
  created() {
    this.getOrderLists();
    document.addEventListener("scroll", this.getscroll);
  },
  // 获取滚动条的位置
  mounted() {
    this.active = -1;
  },
  methods: {
    getscroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离c
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        if (this.loginShow) {
          this.pageNum++;
          this.showText = true;
          this.getOrderLists();
        } else {
        }
      }
    },
    // 获取订单
    getOrderLists() {
      console.log(this.loginShow);
      if (this.loginShow) {
        getOrderList({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          status: this.status,
        }).then((res) => {
          console.log(res);
          if (res.code == "200") {
            if (res.data.length < 8) {
              console.log(1111);
              this.loginShow = false;
              this.showText = true;
              this.textLogin = "我是有底线的~~~~";
            }
            res.data.forEach((req) => {
              var createTime = req.createTime.replace(/\s[\x00-\xff]*/g, "");
              req.createTime = createTime;
            });
            this.orderAll = [...this.orderAll, ...res.data];
            // alert(JSON.stringify(this.orderAll));
            if (this.orderAll.length == 0) {
              this.showkong = true;
              this.textLogin = "";
            } else {
              this.showkong = false;
            }
            console.log(this.orderAll);
            // alert(JSON.stringify(this.orderAll));
          }
        });
      }
    },

    // 继续操作
    gohet(item) {
      console.log(item);
      getOrderStatus({
        orderId: item.id,
      }).then((res) => {
        console.log(res);
        let ordei = JSON.stringify(res.data.orderId);
        window.localStorage.setItem("withhold_orderId", ordei);
        if ((res.code = "200")) {
          if (res.data.orderProcessStatus == "201") {
            // 跳到合同的验证码
            this.$router.push({
              path: "/withholdbincj",
              query: { slysublis: res.data, weslysublis: true },
            });
          } else if (res.data.orderProcessStatus == "301") {
            //  301 判断刷脸状态 0 到合同签署 1到刷脸
            if (res.data.isFaceAuth == "0") {
              this.$router.push({
                path: "/codewait",
                query: { sign: res.data, wedfe: true },
              });
            } else {
              this.$router.push({
                path: "/withholdbincj",
                query: { faceSwiping: 1 },
              });
            }
          } else {
            // 401 刷新当前页面
            window.reload();
          }
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
      // this.$router.push({
      //   path: "/withholdbincj",
      //   query: { id: 10 },
      // });
    },

    // 查看下面列表
    changeItem(num) {
      console.log(num);
      if (num.orderStatus == "5") {
        this.leiData = [];
        return;
      }
      console.log(num);
      getOrderPlanListt({
        orderId: num.id,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          res.data.forEach((req) => {
            var planTime = req.planTime.replace(/\s[\x00-\xff]*/g, "");
            req.planTime = planTime;
          });
          this.leiData = res.data;
        }
      });
    },
    // 修改标签
    changedata(value) {
      if (value == 0) {
        this.status = -1;
      } else {
        this.status = value;
      }
      console.log(this.status);
      this.loginShow = true;
      this.orderAll = [];
      this.textLogin = "";
      this.pageNum = 1;
      this.getOrderLists();
    },

    // 取消订单弹框
    outOrder(num) {
      let that = this;
      this.$dialog
        .alert({
          title: "取消订单",
          message: "取消订单，无法再进行扣款,你确认取消吗？",
          beforeClose,
          showConfirmButton: true, //确认按钮
          showCancelButton: true, //取消按钮
        })
        .catch(() => {});
      function beforeClose(action, done) {
        if (action === "confirm") {
          // 确认按钮
          that.affirmOutOrder(num);
          done();
        } else {
          // 取消按钮
          done();
        }
      }
    },
    // 取消订单确认
    affirmOutOrder(num) {
      console.log(num);
      cancelOrder({
        orderId: num,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.$notify({ type: "success", message: res.message });
          window.location.reload();
        }
      });
    },

    // gobancks() {
    //   this.$router.push("/home");
    // },

    // 查看详情
    lookDetails(data, item, deductionType) {
      console.log(data);
      console.log(item);
      this.$router.push({
        path: "/lookdetaile",
        query: { data: data, name: item, leix: deductionType },
      });
    },

    goback() {
      this.$router.push("/mine"); //返回上一层
    },
  },
};
</script>

<style lang="scss" scoped>
.box_bidu {
  width: 100%;

  .tabsty {
    margin-top: 0.4rem;
    .busPaStyle {
      padding: 0.8rem;
      .logindata {
        height: 2rem;
        line-height: 2rem;
        width: 100%;
        text-align: center;
        color: #e6e6e6;
      }
      .inxx_nimg {
        width: 10rem;
        height: 10rem;
        margin: 0 auto;
        margin-top: 9rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .stas {
    span {
      color: red;
    }
  }

  .line {
    width: 100%;
    height: 0.0049rem;

    background: #e6e6e6;
    margin: 0 auto;
  }
  .in_box {
    position: relative;
    .outOrder {
      position: absolute;
      left: 64%;
      top: 0%;
    }
  }
  .insas_box {
    position: relative;
    .lookdetails {
      position: absolute;
      left: 77%;
      top: 34%;
    }
  }
}
</style>
