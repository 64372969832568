import { render, staticRenderFns } from "./withholdList.vue?vue&type=template&id=3bf7e159&scoped=true&"
import script from "./withholdList.vue?vue&type=script&lang=js&"
export * from "./withholdList.vue?vue&type=script&lang=js&"
import style0 from "./withholdList.vue?vue&type=style&index=0&id=3bf7e159&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bf7e159",
  null
  
)

export default component.exports